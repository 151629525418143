<template lang="pug">
div.d-flex.flex-column
  HeaderGrid(v-if="showHeaderGrid" userFilter="invoiceFilters" :uid="uid" :name="name" :dateRanger="dateRanger" :templateMode="templateMode" :template="template"  :dateRangeDefault="dateRangeDefault" :searchButton="searchButton" :selectedRows="selectedRows" @setDates="setDates" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
  StatCards
      div.cursor-pointer.pl-1.col-card-list-stats(@click="changeTab('all')")
        .card-list-stats(:class="tabActive == 'all' ? 'current' : ''")
          .card-list-stats-title
            div
              span.card-list-stats-title-badge.material-icons.text-primary.mr-50 list
              span.card-list-stats-title-text TOUT
            .card-list-stats-title-badge.bg-primary.text-white {{ getCountDataByStatus("all") }}
          .card-sum
            | {{ getSumDataByStatus("all") }} 
            span {{ tvaMode }}
      div.cursor-pointer.pl-1.col-card-list-stats(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value)")
        .card-list-stats(:class="[tabActive == status.value ? 'current' : '', 'border-'+status.class]")
          .card-list-stats-title
            div
              span.card-list-stats-title-badge.material-icons.mr-25(:class="'text-'+status.class") {{ status.icon }}
              span.card-list-stats-title-text {{ status.label }}
            .card-list-stats-title-badge.text-white(:class="'bg-'+status.class") {{ getCountDataByStatus(status.value, status.type) }}
          .card-sum
            | {{ getSumDataByStatus(status.value, status.type) }} 
            span {{ status.type == 'remaining' || status.value == 6 || status.value == 7 ? 'TTC' : tvaMode }}
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingHoldbacksList')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement de la liste des retenues de garanties...
    sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setSelectedRows="setSelectedRows")
  ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
    sidebar-holdback-content(ref='sidebarHoldbackContent' @refreshRow="refreshRow" @closeSidebar="closeSidebar")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { holdbackStatus } from "@/types/api-orisis/enums/enums";

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";
import StatCards from "@/components/global/grid/StatCards.vue";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

import SidebarHoldbackContent from "@/components/invoice/SidebarHoldbackContent.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  components: {
    SyncGrid,
    HeaderGrid,
    StatCards,
    SidebarHoldbackContent,
    "ejs-sidebar": SidebarComponent,
  },
  props: {
    origine: {
      type: String,
      default: "",
    },
    loadData: {
      type: Boolean,
      default: true,
    },
    showHeaderGrid: {
      type: Boolean,
      default: true,
    },
    showCardStats: {
      type: Boolean,
      default: true,
    },
    tab: {
      default: "all",
    },
    dateRange: {
      type: Array,
      default: () => [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
    },
    filterAffairs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      tabActive: "all",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "holdbacks",
      uid: "holdbacks",
      statusList: holdbackStatus.filter((el) => !el.notUse),
      headerData: [],
      headerLoaded: false,
      selectedRows: [],
      documentSelected: {},
      tvaMode: "TTC",
      dateRanger: true,
      tvaModeButton: true,
      searchButton: true,
      searchPlaceholder: "Rechercher une retenue de garantie",
      template: false,
      templateMode: false,
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 110,
        maxWidth: 110,
        template: "statusTemplate",
        nature: "holdbackStatus",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filterTemplate: "statusFilterTemplate",
      },
      {
        type: "string",
        field: "documentReference",
        headerText: "Facture",
        width: 220,
        maxWidth: 220,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "companyName",
        headerText: "Client/Chantier",
        width: 160,
        maxWidth: 160,
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        field: "date",
        headerText: "En date du",
        format: "formatDateOptions",
        template: "dateYmdTemplate",
        width: 95,
        maxWidth: 95,
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        field: "limiteDate",
        headerText: "Échéance au",
        template: "dateYmdTemplate",
        format: "formatDateOptions",
        width: 100,
        maxWidth: 100,
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "number",
        field: "totalTtc",
        headerText: "Total TTC",
        textAlign: "right",
        width: 95,
        maxWidth: 95,
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        unavailableExport: true,
        format: "formatCurrency",
        allowGrouping: false,
        allowAggregate: true,
      },
      {
        type: "number",
        field: "remainingToPayTtc",
        headerText: "Reste à payer",
        maxWidth: 150,
        textAlign: "right",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        allowGrouping: false,
        allowAggregate: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "holdbacksList",
      "isLoadingHoldbacksList",
      "userParametersList",
    ]),
    dataHoldbacksList() {
      return this.holdbacksList;
    },
    dataOrigine() {
      return this.holdbacksList.filter((elem) =>
        this.filterAffairs.length > 0
          ? this.filterAffairs.includes(elem.affairId)
          : true
      );
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          return this.dataHoldbacksList.filter((elem) =>
            this.filterAffairs.length > 0
              ? this.filterAffairs.includes(elem.affairId)
              : true
          );
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          return this.dataHoldbacksList.filter(
            (elem) =>
              (statusInfos.query
                ? statusInfos.query.includes(elem.status)
                : elem.status === this.tabActive) &&
              (this.filterAffairs.length > 0
                ? this.filterAffairs.includes(elem.affairId)
                : true)
          );
        }
      } else {
        return [];
      }
    },
  },
  async activated() {
    this.activated();
  },
  methods: {
    ...mapActions(["getHoldbacks"]),
    setColumnsByObject,
    formatCurrency,
    updateTvaMode(val) {
      this.tvaMode = val;
    },

    async activated() {
      this.tabActive = this.tab;
      this.headerLoader = false;
      let filter = this.userParametersList.find(
        (el) => el.key == "holdbacksFilters"
      );

      if (!this.loadData) {
        if (this.holdbacksList.length) {
          await this.setColumnsByObject(this.holdbacksList[0], null, this);
        }
        this.headerLoader = true;
      } else if (filter) {
        if (JSON.parse(filter.value).dateRange) {
          this.setDates(JSON.parse(filter.value).dateRange);
        } else {
          this.setDates({
            startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
            endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
          });
        }
      } else {
        await this.setDates({
          startDate: dayjs()
            .subtract(3, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
        });
      }
      this.$emit("setStatusList", this.statusList);
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    clickAction() {
      //- this.$refs.popupNewInvoice.toggleModal();
    },
    getCountDataByStatus(status, statusType = null) {
      if (status === "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        if (statusType == "remaining") {
          return this.dataOrigine.filter(
            (elem) =>
              elem.remaningToPayTtc > 0 &&
              statusInfos.query.includes(elem.status).length
          );
        } else {
          return this.dataOrigine.filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          ).length;
        }
      }
    },
    async setDates(dates) {
      this.headerLoaded = false;
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getHoldbacks({
          dateFrom: dateRange.startDate,
          date: dateRange.endDate,
        }).then(async (res) => {
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    getSumDataByStatus(status = null, statusType = null) {
      let sum = 0;
      const filteredData = this.dataOrigine.filter((obj) => {
        if (status === "all") return true;
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return statusInfos.query
          ? statusInfos.query.includes(obj.status)
          : obj.status === status;
      });

      filteredData.forEach((obj) => {
        let amount;
        if (this.tvaMode == "TTC") {
          amount = parseFloat(obj["totalTtc"]);
        } else {
          amount = parseFloat(obj["totalHt"] || obj["totalTtc"]);
        }
        if (!isNaN(amount)) {
          sum += amount;
        }
      });

      return this.formatCurrency(sum);
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      if (this.origine == "dashbord-gestion") {
        this.$emit("rowClicked", event);
      } else {
        this.isOpen = true;
        this.$refs["sidebarHoldbackContent"].getHoldbackDetails(
          event.rowData.id
        );
        this.$refs.sidebar.toggle();
      }
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
