<template lang="pug">
div.h-100
    .h-100(v-if='isLoadingHoldback')
      .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement des détails de la retenue de garantie...
    .h-100(v-else)
      .sidebar-header.pt-1.px-2
        div
          h3 Détail de la retenue de garantie ({{ holdback.documentReference }})
        feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='closeSidebar')
      .sidebar-header.pb-1.px-2
        .d-flex
          Status(:statusId="holdback.status" nature="holdback" :key="holdback.id+'_'+holdback.status")
          span {{ holdback.date ? "En date du : " + formatDate(holdback.date) : "" }}
        .end
      vs-divider.m-0
      .sidebar-action.py-05.px-2
        .d-flex.align-items-center.justify-content-between.w-100
          .d-flex
            b-button-group
              b-button(v-if="holdback.remainingToPayTtc > 0" @click="payHoldback(holdback.id, false)" style="border-left:1px solid #ccc !important; " class="btn-icon d-flex" variant="primary")
                feather-icon(icon="FilePlusIcon") 
                span(style='margin-left:3px') Ajouter un règlement
          .d-flex.align-items-center.justify-end(v-if="holdback.status == 0 || holdback.status == 1")
      vs-divider.m-0(v-if="holdback.status == 0 || holdback.status == 1")
      .sidebar-content.with-tabs
        div(v-if='holdback.companyId')
          .d-flex.justify-content-between
            h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@mouseover="showCompanyEdit=true" @mouseleave="showCompanyEdit=false" style="flex: 1;")
              span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                feather-icon(icon='ChevronRightIcon') 
                | {{ holdback.companyName ? holdback.companyName : '' }}
                feather-icon.ml-50(@click="editCompany(holdback.companyId, 'client')" v-show="showCompanyEdit" icon='ExternalLinkIcon')
            .d-flex
              div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
              div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                div.px-50
                  small Client
          div(v-if="holdback.companyDetails && holdback.companyDetails.addresses.length")
            span(v-show='holdback.companyDetails.addresses[0].address') {{ holdback.companyAdress }}
              br
            span(v-show='holdback.companyDetails.addresses[0].addressComplement') {{ holdback.companyAddressComplement }}
              br
            span(v-show='holdback.companyDetails.addresses[0].city || holdback.companyDetails.addresses[0].zipCode') {{ holdback.companyZipCode }} {{ holdback.companyCity }}
              br
            span(v-show='holdback.companyDetails.addresses[0].country') {{ holdback.companyCountry }}
              br
            span(v-show='holdback.companyDetails.addresses[0].phone') {{ holdback.companyDetails.phoneNumber }}
          vs-divider
        
        div(v-if='holdback.affairId')
          .d-flex.justify-content-between.mb-1
              h5.mb-0.d-flex.mb-0.text-primary.cursor-pointer(@mouseover="showAffairEdit=true" @mouseleave="showAffairEdit=false" style="flex: 1;")
                span(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ holdback.affairName ? holdback.affairName : '' }}
                  feather-icon.ml-50(@click="editAffair(holdback.affairId)" v-show="showAffairEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Affaire liée
        vs-divider
        div
            h4.text-primary Détails
            ul(class="listTab")
              li
                span Référence de facture
                span {{ holdback.documentReference ? holdback.documentReference : "" }}
              li
                span Échéance au
                span {{ holdback.limiteDate ? this.formatDate(holdback.limiteDate) : "" }}
              li
                span Reste à payer
                span {{ formatCurrency(holdback.remainingToPayTtc) }}
              li
                span Total TTC
                span {{ formatCurrency(holdback.totalTtc) }}  
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";

import {
  formatCurrency,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";

import dayjs from "dayjs";

import TotalDocument from "@/components/invoice/builder/TotalDocument";
import Status from "@/components/global/status/Status";
export default {
  data() {
    return {
      dayjs: dayjs,
      showCompanyEdit: false,
      showAffairEdit: false,
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingIntervention",
      "isLoadingHoldback",
      "isDeletingIntervention",
      "companiesList",
      "affairsList",
      "holdbacksList",
      "customFieldTemplatesList",
    ]),
    holdback: {
      get() {
        return this.$store.getters.holdback;
      },
      set(value) {
        return this.$store.commit("SET_HOLDBACK", value);
      },
    },
  },
  methods: {
    ...mapActions(["getHoldbackById", "updateHoldback"]),
    formatCurrency,
    formatDate,
    async showAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "affair",
        params: {
          id: id,
          title: "Affaire : " + this.holdback.affairName,
          tips: "Affaire : " + this.holdback.affairName,
          routeOrigine: "affairs",
        },
      });
    },
    payHoldback() {
      this.closeSidebar();
      this.$router.push({
        name: "new-payment-holdback",
        params: {
          id: 0,
          documentData: structuredClone(this.holdback),
          title: "Nouveau règlement",
          routeOrigine: "holdbacks",
        },
      });
    },
    async editCompany(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title: "Edit : " + this.holdback.companyName,
          tips: "Editer l'entreprise : " + this.holdback.companyName,
          //routeOrigine: "companies",
        },
      });
    },
    async editAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-affair",
        params: {
          id: id,
          title: "Edit : " + this.holdback.affairName,
          tips: "Editer l'affaire : " + this.holdback.affairName,
          //routeOrigine: "affairs",
        },
      });
    },
    async getHoldbackDetails(id) {
      if (id != "") {
        this.getHoldbackById({
          holdbackId: id
        }).then((res) => {
          this.holdback = {
            ...res,
            date: [res.date],
            companyDetails: this.companiesList.find(
              (elem) => elem.id == res.companyId
            ),
            affairDetails: this.affairsList.find(
              (elem) => elem.id == res.affairId
            ),
          };
        });
      }
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  components: {
    VuePerfectScrollbar,
    Status,
    TotalDocument,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-action {
  flex-direction: column;
}

.cancel-modal-buttons {
  display: flex;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;

    .dropdown-content-item {
      padding: 10px 10px 10px 10px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdownbutton-redirect {
  background-color: #0000;
  border-color: #0000;
  color: #5c636a;
}
</style>
